import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import { FormFragmentFragmentDoc } from './fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListDashboardCommandFormsQueryVariables = Types.Exact<{
  commandIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type ListDashboardCommandFormsQuery = { __typename?: 'Query', listDashboardCommandForms: { __typename?: 'DashboardCommandFormListResponse', items?: Array<{ __typename?: 'FormObject', id: string, formLink: string, fields: Array<{ __typename?: 'FormField', title: string, locale: Types.Locales }> }> | null, error?: { __typename?: 'DashboardCommandFormListResponseErrorObject', code?: string | null, message: string } | null } };

export type ListDonorDashboardCommandFormsQueryVariables = Types.Exact<{
  commandIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type ListDonorDashboardCommandFormsQuery = { __typename?: 'Query', listDonorDashboardCommandForms: { __typename?: 'DashboardCommandFormListResponse', items?: Array<{ __typename?: 'FormObject', id: string, formLink: string, fields: Array<{ __typename?: 'FormField', title: string, locale: Types.Locales }> }> | null, error?: { __typename?: 'DashboardCommandFormListResponseErrorObject', code?: string | null, message: string } | null } };

export type RetrieveFormQueryVariables = Types.Exact<{
  formInput: Types.FormInput;
}>;


export type RetrieveFormQuery = { __typename?: 'Query', retrieveForm: { __typename?: 'FrontendFormObjectResponseObject', object?: { __typename?: 'FrontendFormObject', id: string, path: string, locale: Types.Locales, formCategory: Types.FormCategory, campaignId?: string | null, generateQrCode?: boolean | null, seats?: number | null, fields: Array<{ __typename?: 'FormField', id: string, locale: Types.Locales, title: string }>, rates?: Array<{ __typename?: 'TicketingDetailsRate', id: string, seats?: number | null, fields: Array<{ __typename?: 'RateFieldObject', id: string, title: string, locale: Types.Locales }> }> | null } | null, error?: { __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject', code?: string | null, message: string } | null } };

export type ListFormsForManualCommandQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListFormsForManualCommandQuery = { __typename?: 'Query', listFormsForManualCommand: { __typename?: 'FrontendFormObjectIicjRGRH', items?: Array<{ __typename?: 'FrontendFormObject', id: string, campaignId?: string | null, formType: Types.FormType, formCategory: Types.FormCategory, isRegistration?: boolean | null, closed?: boolean | null, locale: Types.Locales, fields: Array<{ __typename?: 'FormField', id: string, title: string, locale: Types.Locales }> }> | null } };

export type GetOrganizationFormsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['String'];
}>;


export type GetOrganizationFormsQuery = { __typename?: 'Query', getOrganizationForms: { __typename?: 'FrontendFormObjectResponseListObject', items?: Array<{ __typename?: 'FrontendFormObject', id: string, path: string, formType: Types.FormType, formCategory: Types.FormCategory, closed?: boolean | null, locale: Types.Locales, campaignId?: string | null, teamId?: string | null, isPrimaryCampaign?: boolean | null, isPrimaryTeam?: boolean | null, fields: Array<{ __typename?: 'FormField', id: string, title: string, locale: Types.Locales }> }> | null } };

export type GetFormVolumeQueryVariables = Types.Exact<{
  formInput: Types.FormInput;
}>;


export type GetFormVolumeQuery = { __typename?: 'Query', getFormVolume: { __typename?: 'Numberf6mx4D2z', object?: number | null } };

export type ListCampaignFormTitlesQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String'];
}>;


export type ListCampaignFormTitlesQuery = { __typename?: 'Query', listCampaignFormTitles: { __typename?: 'FormTitleListResponse', items?: Array<{ __typename?: 'FormTitle', id: string, fields: Array<{ __typename?: 'FormField', title: string, locale: Types.Locales }> }> | null } };

export type ListOrganizationFormTitlesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListOrganizationFormTitlesQuery = { __typename?: 'Query', listOrganizationFormTitles: { __typename?: 'FormTitleListResponse', items?: Array<{ __typename?: 'FormTitle', id: string, fields: Array<{ __typename?: 'FormField', title: string, locale: Types.Locales }> }> | null } };


export const ListDashboardCommandFormsDocument = gql`
    query listDashboardCommandForms($commandIds: [String!]!) {
  listDashboardCommandForms(commandIds: $commandIds) {
    items {
      id
      formLink
      fields {
        title
        locale
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useListDashboardCommandFormsQuery__
 *
 * To run a query within a React component, call `useListDashboardCommandFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDashboardCommandFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDashboardCommandFormsQuery({
 *   variables: {
 *      commandIds: // value for 'commandIds'
 *   },
 * });
 */
export function useListDashboardCommandFormsQuery(baseOptions: Apollo.QueryHookOptions<ListDashboardCommandFormsQuery, ListDashboardCommandFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDashboardCommandFormsQuery, ListDashboardCommandFormsQueryVariables>(ListDashboardCommandFormsDocument, options);
      }
export function useListDashboardCommandFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDashboardCommandFormsQuery, ListDashboardCommandFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDashboardCommandFormsQuery, ListDashboardCommandFormsQueryVariables>(ListDashboardCommandFormsDocument, options);
        }
export type ListDashboardCommandFormsQueryHookResult = ReturnType<typeof useListDashboardCommandFormsQuery>;
export type ListDashboardCommandFormsLazyQueryHookResult = ReturnType<typeof useListDashboardCommandFormsLazyQuery>;
export type ListDashboardCommandFormsQueryResult = Apollo.QueryResult<ListDashboardCommandFormsQuery, ListDashboardCommandFormsQueryVariables>;
export const ListDonorDashboardCommandFormsDocument = gql`
    query listDonorDashboardCommandForms($commandIds: [String!]!) {
  listDonorDashboardCommandForms(commandIds: $commandIds) {
    items {
      id
      formLink
      fields {
        title
        locale
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useListDonorDashboardCommandFormsQuery__
 *
 * To run a query within a React component, call `useListDonorDashboardCommandFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDonorDashboardCommandFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDonorDashboardCommandFormsQuery({
 *   variables: {
 *      commandIds: // value for 'commandIds'
 *   },
 * });
 */
export function useListDonorDashboardCommandFormsQuery(baseOptions: Apollo.QueryHookOptions<ListDonorDashboardCommandFormsQuery, ListDonorDashboardCommandFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDonorDashboardCommandFormsQuery, ListDonorDashboardCommandFormsQueryVariables>(ListDonorDashboardCommandFormsDocument, options);
      }
export function useListDonorDashboardCommandFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDonorDashboardCommandFormsQuery, ListDonorDashboardCommandFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDonorDashboardCommandFormsQuery, ListDonorDashboardCommandFormsQueryVariables>(ListDonorDashboardCommandFormsDocument, options);
        }
export type ListDonorDashboardCommandFormsQueryHookResult = ReturnType<typeof useListDonorDashboardCommandFormsQuery>;
export type ListDonorDashboardCommandFormsLazyQueryHookResult = ReturnType<typeof useListDonorDashboardCommandFormsLazyQuery>;
export type ListDonorDashboardCommandFormsQueryResult = Apollo.QueryResult<ListDonorDashboardCommandFormsQuery, ListDonorDashboardCommandFormsQueryVariables>;
export const RetrieveFormDocument = gql`
    query retrieveForm($formInput: FormInput!) {
  retrieveForm(formInput: $formInput) {
    object {
      ...FormFragment
    }
    error {
      code
      message
    }
  }
}
    ${FormFragmentFragmentDoc}`;

/**
 * __useRetrieveFormQuery__
 *
 * To run a query within a React component, call `useRetrieveFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveFormQuery({
 *   variables: {
 *      formInput: // value for 'formInput'
 *   },
 * });
 */
export function useRetrieveFormQuery(baseOptions: Apollo.QueryHookOptions<RetrieveFormQuery, RetrieveFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveFormQuery, RetrieveFormQueryVariables>(RetrieveFormDocument, options);
      }
export function useRetrieveFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveFormQuery, RetrieveFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveFormQuery, RetrieveFormQueryVariables>(RetrieveFormDocument, options);
        }
export type RetrieveFormQueryHookResult = ReturnType<typeof useRetrieveFormQuery>;
export type RetrieveFormLazyQueryHookResult = ReturnType<typeof useRetrieveFormLazyQuery>;
export type RetrieveFormQueryResult = Apollo.QueryResult<RetrieveFormQuery, RetrieveFormQueryVariables>;
export const ListFormsForManualCommandDocument = gql`
    query listFormsForManualCommand {
  listFormsForManualCommand {
    items {
      id
      campaignId
      formType
      formCategory
      isRegistration
      closed
      locale
      fields {
        id
        title
        locale
      }
    }
  }
}
    `;

/**
 * __useListFormsForManualCommandQuery__
 *
 * To run a query within a React component, call `useListFormsForManualCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFormsForManualCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFormsForManualCommandQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFormsForManualCommandQuery(baseOptions?: Apollo.QueryHookOptions<ListFormsForManualCommandQuery, ListFormsForManualCommandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFormsForManualCommandQuery, ListFormsForManualCommandQueryVariables>(ListFormsForManualCommandDocument, options);
      }
export function useListFormsForManualCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFormsForManualCommandQuery, ListFormsForManualCommandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFormsForManualCommandQuery, ListFormsForManualCommandQueryVariables>(ListFormsForManualCommandDocument, options);
        }
export type ListFormsForManualCommandQueryHookResult = ReturnType<typeof useListFormsForManualCommandQuery>;
export type ListFormsForManualCommandLazyQueryHookResult = ReturnType<typeof useListFormsForManualCommandLazyQuery>;
export type ListFormsForManualCommandQueryResult = Apollo.QueryResult<ListFormsForManualCommandQuery, ListFormsForManualCommandQueryVariables>;
export const GetOrganizationFormsDocument = gql`
    query getOrganizationForms($organizationId: String!) {
  getOrganizationForms(organizationId: $organizationId) {
    items {
      id
      path
      formType
      formCategory
      closed
      locale
      campaignId
      teamId
      isPrimaryCampaign
      isPrimaryTeam
      fields {
        id
        title
        locale
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationFormsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationFormsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationFormsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationFormsQuery, GetOrganizationFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationFormsQuery, GetOrganizationFormsQueryVariables>(GetOrganizationFormsDocument, options);
      }
export function useGetOrganizationFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationFormsQuery, GetOrganizationFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationFormsQuery, GetOrganizationFormsQueryVariables>(GetOrganizationFormsDocument, options);
        }
export type GetOrganizationFormsQueryHookResult = ReturnType<typeof useGetOrganizationFormsQuery>;
export type GetOrganizationFormsLazyQueryHookResult = ReturnType<typeof useGetOrganizationFormsLazyQuery>;
export type GetOrganizationFormsQueryResult = Apollo.QueryResult<GetOrganizationFormsQuery, GetOrganizationFormsQueryVariables>;
export const GetFormVolumeDocument = gql`
    query getFormVolume($formInput: FormInput!) {
  getFormVolume(formInput: $formInput) {
    object
  }
}
    `;

/**
 * __useGetFormVolumeQuery__
 *
 * To run a query within a React component, call `useGetFormVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormVolumeQuery({
 *   variables: {
 *      formInput: // value for 'formInput'
 *   },
 * });
 */
export function useGetFormVolumeQuery(baseOptions: Apollo.QueryHookOptions<GetFormVolumeQuery, GetFormVolumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormVolumeQuery, GetFormVolumeQueryVariables>(GetFormVolumeDocument, options);
      }
export function useGetFormVolumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormVolumeQuery, GetFormVolumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormVolumeQuery, GetFormVolumeQueryVariables>(GetFormVolumeDocument, options);
        }
export type GetFormVolumeQueryHookResult = ReturnType<typeof useGetFormVolumeQuery>;
export type GetFormVolumeLazyQueryHookResult = ReturnType<typeof useGetFormVolumeLazyQuery>;
export type GetFormVolumeQueryResult = Apollo.QueryResult<GetFormVolumeQuery, GetFormVolumeQueryVariables>;
export const ListCampaignFormTitlesDocument = gql`
    query listCampaignFormTitles($campaignId: String!) {
  listCampaignFormTitles(campaignId: $campaignId) {
    items {
      id
      fields {
        title
        locale
      }
    }
  }
}
    `;

/**
 * __useListCampaignFormTitlesQuery__
 *
 * To run a query within a React component, call `useListCampaignFormTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignFormTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignFormTitlesQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useListCampaignFormTitlesQuery(baseOptions: Apollo.QueryHookOptions<ListCampaignFormTitlesQuery, ListCampaignFormTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCampaignFormTitlesQuery, ListCampaignFormTitlesQueryVariables>(ListCampaignFormTitlesDocument, options);
      }
export function useListCampaignFormTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCampaignFormTitlesQuery, ListCampaignFormTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCampaignFormTitlesQuery, ListCampaignFormTitlesQueryVariables>(ListCampaignFormTitlesDocument, options);
        }
export type ListCampaignFormTitlesQueryHookResult = ReturnType<typeof useListCampaignFormTitlesQuery>;
export type ListCampaignFormTitlesLazyQueryHookResult = ReturnType<typeof useListCampaignFormTitlesLazyQuery>;
export type ListCampaignFormTitlesQueryResult = Apollo.QueryResult<ListCampaignFormTitlesQuery, ListCampaignFormTitlesQueryVariables>;
export const ListOrganizationFormTitlesDocument = gql`
    query listOrganizationFormTitles {
  listOrganizationFormTitles {
    items {
      id
      fields {
        title
        locale
      }
    }
  }
}
    `;

/**
 * __useListOrganizationFormTitlesQuery__
 *
 * To run a query within a React component, call `useListOrganizationFormTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationFormTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationFormTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrganizationFormTitlesQuery(baseOptions?: Apollo.QueryHookOptions<ListOrganizationFormTitlesQuery, ListOrganizationFormTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationFormTitlesQuery, ListOrganizationFormTitlesQueryVariables>(ListOrganizationFormTitlesDocument, options);
      }
export function useListOrganizationFormTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationFormTitlesQuery, ListOrganizationFormTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationFormTitlesQuery, ListOrganizationFormTitlesQueryVariables>(ListOrganizationFormTitlesDocument, options);
        }
export type ListOrganizationFormTitlesQueryHookResult = ReturnType<typeof useListOrganizationFormTitlesQuery>;
export type ListOrganizationFormTitlesLazyQueryHookResult = ReturnType<typeof useListOrganizationFormTitlesLazyQuery>;
export type ListOrganizationFormTitlesQueryResult = Apollo.QueryResult<ListOrganizationFormTitlesQuery, ListOrganizationFormTitlesQueryVariables>;